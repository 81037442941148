// import React from "react";

// function ContactInfo() {
//     return (
//         <div style={{ color: "#343a40" }}>
//             <h5 className="mb-3" style={{ color: "#495057", fontSize: '1.25rem' }}>Naročila Asfalta:</h5>
//             <p style={{ fontSize: '1.1rem' }}>GSM: <a href="tel:+38741756560" style={{ color: "#007bff", textDecoration: "none" }}>041 604 735</a></p>
//             <p style={{ fontSize: '1.1rem' }}>Tel: <a href="tel:+38727995697" style={{ color: "#007bff", textDecoration: "none" }}>02 7995697</a></p>
//             <p style={{ fontSize: '1.1rem' }}>Email: <a href="mailto:tak2@siol.net" style={{ color: "#007bff", textDecoration: "none" }}>tak3@siol.net</a></p>

//             <h5 className="mt-4" style={{ color: "#495057", fontSize: '1.25rem' }}>Direktor:</h5>
//             <p style={{ fontSize: '1.1rem' }}>Iztok Dobnik</p>
//             <p style={{ fontSize: '1.1rem' }}>Tel: <a href="tel:+38727995696" style={{ color: "#007bff", textDecoration: "none" }}>02 7995 696</a></p>
//             <p style={{ fontSize: '1.1rem' }}>GSM: <a href="tel:+38741756560" style={{ color: "#007bff", textDecoration: "none" }}>041 756 560</a></p>
//             <p style={{ fontSize: '1.1rem' }}>Email: <a href="mailto:iztok.dobnik@siol.net" style={{ color: "#007bff", textDecoration: "none" }}>iztok.dobnik@siol.net</a></p>

//             <h5 className="mt-4" style={{ color: "#495057", fontSize: '1.25rem' }}>Tajništvo:</h5>
//             <p style={{ fontSize: '1.1rem' }}>Tel: <a href="tel:+38727995695" style={{ color: "#007bff", textDecoration: "none" }}>02 7995 695</a></p>
//             <p style={{ fontSize: '1.1rem' }}>Fax: <a href="tel:+38727995698" style={{ color: "#007bff", textDecoration: "none" }}>02 7995 698</a></p>
//             <p style={{ fontSize: '1.1rem' }}>Email: <a href="mailto:tak2@siol.net" style={{ color: "#007bff", textDecoration: "none" }}>tak2@siol.net</a></p>
//         </div>
//     );
// }

// function Map() {
//     return (
//         <div className="d-flex justify-content-center my-4">
//             <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2769.4477966336494!2d15.787385076171462!3d46.39846367062094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDbCsDIzJzU0LjUiTiAxNcKwNDcnMjMuOSJF!5e1!3m2!1sen!2ssi!4v1729886739386!5m2!1sen!2ssi"
//              title="Google Map"
//              className="w-100"
//              style={{ border: 0, height: '500px', borderRadius: '8px' }}
//              loading="lazy"
//              referrerPolicy="no-referrer-when-downgrade"
//             ></iframe>
//         </div>
//     );
// }

// function Address() {
//     return (
//         <div style={{ color: "#343a40" }}>
//             <h5 style={{ color: "#495057", fontSize: '1.25rem' }}>Tovarna asfalta Kidričevo d.o.o.</h5>
//             <p style={{ fontSize: '1.1rem' }}>Tovarniška cesta 10</p>
//             <p style={{ fontSize: '1.1rem' }}>2325 Kidričevo</p>
//             <p style={{ fontSize: '1.1rem' }}>Davčna številka SI: 11836270</p>
//             <p style={{ fontSize: '1.1rem' }}>Matična številka: 1673289</p>
//         </div>
//     );
// }

// export function KontaktirajteNas() {
//     return (
//         <div style={{ minHeight: '100vh', backgroundColor: '#ffffff', padding: '30px' }}>
//             <div className="container" style={{ backgroundColor: "#f8f9fa", padding: '30px', borderRadius: '8px' }}>
//                 <div className="row">
//                     <div className="col-md-4">
//                         <ContactInfo />
//                     </div>
//                     <div className="col-md-4">
//                         <Address />
//                     </div>
//                     <div className="col-md-4">
//                         <Map />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }





import React from "react";

// Common styles
const linkStyle = { color: "#007bff", textDecoration: "none" };
const sectionHeaderStyle = { color: "#495057", fontSize: "1.25rem" };
const textStyle = { fontSize: "1.1rem", color: "#343a40" };

// Reusable Contact Item Component
const ContactItem = ({ label, value, href }) => (
    <p style={textStyle}>
        {label} <a href={href} style={linkStyle}>{value}</a>
    </p>
);

// Contact Information Component
const ContactInfo = () => (
    <div style={textStyle}>
        <h5 className="mb-3" style={sectionHeaderStyle}>Naročila Asfalta:</h5>
        <ContactItem label="GSM:" value="041 604 735" href="tel:+38741756560" />
        <ContactItem label="Tel:" value="02 7995697" href="tel:+38727995697" />
        <ContactItem label="Email:" value="tak3@siol.net" href="mailto:tak2@siol.net" />

        <h5 className="mt-4" style={sectionHeaderStyle}>Direktor:</h5>
        <p style={textStyle}>Iztok Dobnik</p>
        <ContactItem label="Tel:" value="02 7995 696" href="tel:+38727995696" />
        <ContactItem label="GSM:" value="041 756 560" href="tel:+38741756560" />
        <ContactItem label="Email:" value="iztok.dobnik@siol.net" href="mailto:iztok.dobnik@siol.net" />

        <h5 className="mt-4" style={sectionHeaderStyle}>Tajništvo:</h5>
        <ContactItem label="Tel:" value="02 7995 695" href="tel:+38727995695" />
        <ContactItem label="Fax:" value="02 7995 698" href="tel:+38727995698" />
        <ContactItem label="Email:" value="tak2@siol.net" href="mailto:tak2@siol.net" />
    </div>
);



// Address Component with Tovorni Vhod Section
const AddressWithTovorniVhod = () => (
    <div style={textStyle}>
        <h5 style={sectionHeaderStyle}>Tovarna asfalta Kidričevo d.o.o.</h5>
        <p style={textStyle}>Tovarniška cesta 10</p>
        <p style={textStyle}>2325 Kidričevo</p>
        <p style={textStyle}>Davčna številka SI: 11836270</p>
        <p style={textStyle}>Matična številka: 1673289</p>

        <h5 style={{ ...sectionHeaderStyle, marginTop: "20px" }}>Tovorni Vhod</h5>
        <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2769.5784764661826!2d15.778444400000001!3d46.395888899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f7750b9e6656d%3A0x1e7f7248f7f2a520!2sTovarna%20asfalta%20Kidri%C4%8Devo%20d.o.o.!5e0!3m2!1sen!2ssi!4v1632867656066!5m2!1sen!2ssi"
                    title="Tovorni Vhod Map"
                    className="w-100"
                    style={{ border: 0, height: "300px", borderRadius: "8px" }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
    </div>
);

// Main Component
export function KontaktirajteNas() {
    return (
        <div style={{ minHeight: "100vh", backgroundColor: "#ffffff", padding: "30px" }}>
            <div className="container" style={{ backgroundColor: "#f8f9fa", padding: "30px", borderRadius: "8px" }}>
                <div className="row">
                    <div className="col-md-4">
                        <ContactInfo />
                    </div>
                    <div className="col-md-4">
                        <AddressWithTovorniVhod />
                    </div>
                    <div className="col-md-4">
                    <h5 style={{ ...sectionHeaderStyle}}>Tovarna Asfalta</h5>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2769.4477966336494!2d15.787385076171462!3d46.39846367062094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f7750b9e6656d%3A0x1e7f7248f7f2a520!2sTovarna%20asfalta%20Kidri%C4%8Devo%20d.o.o.!5e0!3m2!1sen!2ssi!4v1632864656895!5m2!1sen!2ssi"
                      title="Google Map"
                className="w-100"
                style={{ border: 0, height: "500px", borderRadius: "8px" }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
