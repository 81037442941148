import React from "react";



function Section({ title, services }) {
  return (
    <div>
      <h1 className="text-center mb-4" style={{ fontSize: "2.5rem", color: "black", opacity: "0.9", textShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)", marginTop: "20px" }}>
        {title}
      </h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{backgroundColor: 'rgba(39, 72, 126)'}} className="" scope="col"></th>
            <th scope="col" className="text-white" style={{backgroundColor: 'rgba(39, 72, 126)', fontSize: "1.3rem" }}>Asfalti</th>
          </tr>
        </thead>
        <tbody>
          {services.map((service, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}.</th>
              <td style={{ fontSize: "1.2rem", color: "#212529"}}>{service}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function NasiProizvodi() {
  const proizvodi = [
    "AC4 SURF B 50/70 A5",
    "AC4 SURF B 70/100 A5",
    "AC8 SURF B 50/70 A5",
    "AC8 SURF B 70/100 A5",
    "AC8 SURF B 50/70 A4",
    "AC8 SURF B 70/100 A4",
    "AC8 SURF B 50/70 A4 (Z2)",
    "AC8 SURF B 70/100 A4 (Z2)",
    "AC8 SURF B 50/70 A3 (Z2)",
    "AC8 SURF B 70/100 A3 (Z2)",
    "AC8 SURF B 50/70 A2 (Z2)",
    "AC8 SURF B 70/100 A2 (Z2)",
    "AC8 SURF PMB45/80-65 A2 (Z2)",
    "AC11 SURF B 50/70 A4",
    "AC11 SURF B 70/100 A4",
    "AC11 SURF B 50/70 A4 (Z2)",
    "AC11 SURF B 70/100 A4 (Z2)",
    "AC11 SURF B 50/70 A3 (Z2)",
    "AC11 SURF B 70/100 A3 (Z2)",
    "AC11 SURF B 50/70 A1 A2 (Z2)",
    "AC11 SURF B 70/100 A1 A2 (Z2)",
    "AC11 SURF PMB45/80-65 A1 A2 (Z2)",
    "AC16 SURF B 50/70 A4",
    "AC16 SURF B 70/100 A4",
    "AC16 BASE B 50/70 A3",
    "AC16 BASE B 70/100 A3",
    "AC16 BASE B 50/70 A2",
    "AC16 BASE B 70/100 A2",
    "AC22 BASE B 50/70 A4",
    "AC22 BASE B 70/100 A4",
    "AC22 BASE B 50/70 A3",
    "AC22 BASE B 70/100 A3",
    "AC22 BASE B 50/70 A1 A2",
    "AC22 BASE B 70/100 A1 A2",
    "AC22 BASE PMB45/80-65 A1 A2",
    "AC22 BIN B 50/70 A1 A2",
    "AC22 BIN B 70/100 A1 A2",
    "AC22 BIN PMB45/80-65 A1 A2",
    "AC32 BASE B 50/70 A3",
    "AC32 BASE B 50/70 A1 A2",
    "AC32 BASE PMB45/80-65 A1 A2",
    "AC32 STAB B 50/70 A1 A2",
    "SMA8 PMB45/80-65 A1 A2 (Z2)",
    "SMA11 PMB45/80-65 A1 A2 (Z2)",
    "PA8 PMB45/80-65 A1 A2 (Z2)",
    "PA11 PMB45/80-65 A1 A2 (Z2)",
    "PA11 OG RMB45/80-55 A1 A2 (Z2)",
    "POSIP",
  ];


  return (
    <div className="usluge-wrapper">
      <div className="container">
        <div className="row">
          <div className="col">
            <Section title="NAŠI PROIZVODI" services={proizvodi} />
          </div>
        </div>
      </div>
    </div>
  );
}
