import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Domov } from './Domov';
import { Onas } from './Onas';
import { NasiProizvodi } from './NasiProizvodi';
import { KontaktirajteNas } from './KontaktirajteNas';
import { Footer } from './Footer';

function BasicExample() {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        setExpanded(false); // Reset the navbar state on large screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      <Navbar expanded={expanded} expand="lg" className="bg-body-tertiary sticky-top">
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
            <img style={{ height: '65px' }} src="./img/logo-2.png" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
            className="custom-toggler"
          >
            <span className="custom-toggler-icon">{expanded ? '✕' : '☰'}</span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
                Domov
              </Nav.Link>
              <Nav.Link as={Link} to="/onas" onClick={() => setExpanded(false)}>
                O Nas
              </Nav.Link>
              <Nav.Link as={Link} to="/nasiproizvodi" onClick={() => setExpanded(false)}>
                Naši Proizvodi
              </Nav.Link>
              <Nav.Link as={Link} to="/kontaktirajtenas" onClick={() => setExpanded(false)}>
                Kontakt
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="main-content">
        <Routes>
          <Route path="/" element={<Domov />} />
          <Route path="/onas" element={<Onas />} />
          <Route path="/nasiproizvodi" element={<NasiProizvodi />} />
          <Route path="/kontaktirajtenas" element={<KontaktirajteNas />} />
        </Routes>
      </div>

      <Footer />
    </Router>
  );
}

export default BasicExample;
