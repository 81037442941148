
import './App.css';
import NavbarComponent from './components/NavbarComponent'



function App() {
  return (
    <NavbarComponent />
  );
}

export default App;
