import React from "react";

export function Domov() {
    return (
        <>
            <div className="container-fluid p-0 vh-100 d-flex align-items-center">
                <div className="row no-gutters w-100 h-100">
                    <div className="col-md-3 d-flex flex-column p-0">
                        <img 
                            src="./img/DSC00009.jpg"
                            alt="1"
                            className="img-fluid w-100 h-50 hover-expand"
                            style={{ objectFit: "cover" }}
                        />
                        <img 
                            src="./img/DSC00010.jpg"
                            alt="2"
                            className="img-fluid w-100 h-50 hover-expand"
                            style={{ objectFit: "cover" }}
                        />
                    </div>

                    <div className="col-md-6 d-flex align-items-center justify-content-center p-0 hover-expand-container">
                        <div className="main-image-container">
                            <img 
                                src="./img/DSC00003-1-1030x671.jpg"
                                alt="Main"
                                className="main-image"
                            />
                          <div className="main-text-container">
  <h1
    className="text-center"
    style={{
      color: '#fff', // White text color
      textShadow: '2px 2px 1px rgba(0, 0, 0, 1)' // Shadow effect
    }}
  >
    Tovarna asfalta Kidričevo d.o.o.
  </h1>
</div>
        </div>
                  </div>
                    <div className="col-md-3 d-flex flex-column p-0">
                        <img 
                            src="./img/DSC00004.jpg"
                            alt="1"
                            className="img-fluid w-100 h-50 hover-expand"
                            style={{ objectFit: "cover" }}
                        />
                        <img 
                            src="./img/wet-asphalt-background_1161-253.jpg" 
                            alt="2"
                            className="img-fluid w-100 h-50 hover-expand"
                            style={{ objectFit: "cover" }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
