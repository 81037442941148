import React from "react";
import Carousel from 'react-bootstrap/Carousel';

export function Onas() {
    return (
        <div style={{ 
            position: 'relative', 
            minHeight: '100vh', 
            backgroundColor: '#000', 
            color: '#fff',
            padding: '0',
            overflow: 'hidden'
        }}>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="./img/StockCake-Workers paving road_1725991617.jpg"
                        alt="Slide 1"
                        style={{ objectFit: 'cover', height: '100vh', opacity: '0.6' }}
                    />
                    <Carousel.Caption
                        style={{ 
                            display: 'block', 
                            textAlign: 'center', 
                            color: '#fff',
                            maxWidth: '80%',
                            margin: '0 auto', 
                            padding: '20px'
                        }}
                    >
                        <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>O PODJETJU</h1>
                        <p style={{ fontSize: '1.25rem', marginTop: '10px' }}>
                            Ukvarjamo se s proizvodnjo asfaltnih mešanic katere proizvedemo v asfaltni bazi AMMANN Universal 120, ki ima zmogljivost do 130t/h in skladiščne kapacitete 240t (4x60t). Pričetek proizvodnje je bil 12.6.2002. Imamo certificirano proizvodnjo katero redno kontrolira zunanji laboratorij INŠTITUT BAM d.o.o.
                            Smo vedno pripravljeni pomagati in se tudi prilagajati potrebam naših strank.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="./img/StockCake-Sunlit asphalt textures_1725992286.jpg"
                        alt="Slide 2"
                        style={{ objectFit: 'cover', height: '100vh', opacity: '0.6' }}
                    />
                    <Carousel.Caption
                        style={{ 
                            display: 'block', 
                            textAlign: 'center', 
                            color: '#fff',
                            maxWidth: '80%',
                            margin: '0 auto', 
                            padding: '20px'
                        }}
                    >
                        <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>O PODJETJU</h1>
                        <p style={{ fontSize: '1.25rem', marginTop: '10px' }}>
                            Ukvarjamo se s proizvodnjo asfaltnih mešanic katere proizvedemo v asfaltni bazi AMMANN Universal 120, ki ima zmogljivost do 130t/h in skladiščne kapacitete 240t (4x60t). Pričetek proizvodnje je bil 12.6.2002. Imamo certificirano proizvodnjo katero redno kontrolira zunanji laboratorij INŠTITUT BAM d.o.o.
                            Smo vedno pripravljeni pomagati in se tudi prilagajati potrebam naših strank.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            {/* Media Query for Responsive Design */}
            <style>
                {`
                    @media (max-width: 768px) {
                        .carousel-caption h1 {
                            font-size: 2rem;
                        }

                        .carousel-caption p {
                            font-size: 1rem;
                            line-height: 1.5;
                        }
                    }

                    @media (max-width: 576px) {
                        .carousel-caption h1 {
                            font-size: 1.5rem;
                        }

                        .carousel-caption p {
                            font-size: 0.875rem;
                        }
                    }
                `}
            </style>
        </div>
    );
}
