import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export function Footer() {
    return (
        <footer className="bg-dark text-light py-4">
            <Container>
                <Row className="text-center text-md-left">
                    <Col xs={12} md={4} className="mb-4 mb-md-0">
                        <ContactInfo />
                    </Col>
                    <Col xs={12} md={4} className="mb-4 mb-md-0">
                        <Map />
                    </Col>
                    <Col xs={12} md={4}>
                        <Address />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="text-center">
                        <Copyright />
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

function ContactInfo() {
    return (
        <div>
            <h5 className="mb-3">Naročila Asfalta:</h5>
            <p>GSM: <a href="tel:+38741756560" className="text-light text-decoration-none">041 604 735</a></p>
            <p>Tel: <a href="tel:+38727995697" className="text-light text-decoration-none">02 7995697</a></p>
            <p>Email: <a href="mailto:tak2@siol.net" className="text-light text-decoration-none">tak3@siol.net</a></p>
        </div>
    );
}

function Map() {
    return (
        <div className="d-flex justify-content-center">
            <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2751.6688294620667!2d15.79517797617126!3d46.39579307080163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f63b731502309%3A0x6fe3dc747284f70!2sTovarna%20asfalta%20Kidri%C4%8Devo%20proizvodnja%20d.o.o.!5e0!3m2!1sen!2ssi!4v1725391341057!5m2!1sen!2ssi"
                className="w-100"
                style={{ border: 0, height: '300px' }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
}

function Address() {
    return (
        <div>
            <h5>Tovarna asfalta Kidričevo d.o.o.</h5>
            <p>Tovarniška cesta 10</p>
            <p>2325 Kidričevo</p>
            <p>Davčna številka SI: 11836270</p>
            <p>Matična številka: 1673289</p>
        </div>
    );
}

function Copyright() {
    return (
        <div>
            <p>© 2024 Tovarna asfalta Kidričevo. All Rights Reserved.</p>
        </div>
    );
}
